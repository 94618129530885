import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import playIcon from '@/../static/assets/images/play.svg';
import pauseIcon from '@/../static/assets/images/pause.svg';
import Reveal from '@/components/reveal/Reveal';
import { BaseComponentProps } from '@/types';

interface HomeHeroProps {
  video: {
    file: {
      url: string;
    };
  };

  videoMp4: {
    file: {
      url: string;
    };
  };

  videoImg: {
    file: {
      url: string;
    };
  };
}

const query = graphql`
  query homeBlockAndHomeBlock {
    video: contentfulAsset(contentful_id: { eq: "3H0Da7qlaT8dC3x2TAFFt" }) {
      file {
        url
      }
    }

    videoMp4: contentfulAsset(contentful_id: { eq: "4Iur8jn7whjo5HvumVYZKO" }) {
      file {
        url
      }
    }

    videoImg: contentfulAsset(contentful_id: { eq: "2Gs31vhRaaXmlUJ4LqXiK6" }) {
      file {
        url
      }
    }
  }
`;

const HeroSection: React.FC<BaseComponentProps> = () => {
  const { video, videoMp4, videoImg }: HomeHeroProps = useStaticQuery(query);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [imgVideoUrl, setImgVideoUrl] = useState(playIcon);
  const bgVideo = video.file.url;
  const bgVideoMp4 = videoMp4.file.url;
  const bgVideoImg = videoImg.file.url;
  const bgVideoRef = useRef<HTMLVideoElement>(null);
  const handlePlayVideo = () => {
    // toggle video play and pause
    setIsVideoPlaying(!isVideoPlaying);
    if (bgVideoRef.current.paused) {
      bgVideoRef.current.play();
    } else {
      bgVideoRef.current.pause();
    }
  };

  useEffect(() => {
    setImgVideoUrl(isVideoPlaying ? playIcon : pauseIcon);
  }, [isVideoPlaying]);

  return (
    <div className="hero">
      <Reveal className="video-container">
        <video
          poster={bgVideoImg}
          ref={bgVideoRef}
          preload="none"
          autoPlay
          muted
          loop
          playsInline
          id="bg-video"
        >
          <source src={bgVideo} type="video/webm" />
          <source src={bgVideoMp4} type="video/mp4" />
        </video>
      </Reveal>

      <Reveal className="container">
        <h1>Pioneering the future of digital commerce</h1>

        <div
          role="button"
          className="play-pause"
          onClick={handlePlayVideo}
          onKeyDown={handlePlayVideo}
          tabIndex={0}
        >
          <img src={imgVideoUrl} alt="Toggle" width="40" height="40" />
        </div>
      </Reveal>
    </div>
  );
};

export default HeroSection;
